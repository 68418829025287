import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useGlobalContext } from '../../context/globalContext';
import { InnerLayout } from '../../styles/Layouts';
import FinancialForm from '../Forms/FinancialForm';
import Item from '../Item/Item';
import { useEffectLazy } from '../../utils/useEffectLazy';

function Income() {
    const {addIncome, error, setError, incomes, getIncomes, deleteIncome, totalIncome} = useGlobalContext()

    const [formValues, setFormValues] = useState({
        title: '',
        amount: '',
        date: null,
        category: '',
        description: '',
    });

    const amountRef = useRef(formValues.amount);
    const currencySwap = async () => {
        let target = 'czk';
        try {
            const response = await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${formValues.currency.toLowerCase()}/${target}.json`);
            let data = await response.json();
            amountRef.current = Math.round(data[target]*formValues.amount);
        } catch (err) {
            setError(`Could not transfer from ${formValues.currency}, use CZK for now.`);
        }
    }

    useEffect(() =>{
        getIncomes();
    }, [])

    const handleFormSubmit = async (values) => {
        addIncome(values);
    }

    useEffectLazy( () => {
        if (formValues.currency != 'CZK') {
            currencySwap().then(() => {
                let submitData = formValues;
                submitData.amount = amountRef.current;
                handleFormSubmit(submitData);
            });
        } else {
            handleFormSubmit(formValues);
        }
        getIncomes();
     }, [formValues])

    return (
        <IncomeStyled data-cy="income-page">
            <InnerLayout>
                <h1 className="income-title" data-cy="header">Incomes</h1>
                <h2 className="total-income" data-cy="total">
                    Total Income: <span data-cy="income-value">{totalIncome()} CZK</span>
                </h2>
                <div className="income-content" data-cy="income-content">
                <div className="form-container" data-cy="income-form-container">
                <FinancialForm formType='income'
                            formValues={formValues} setFormValues={setFormValues}
                />
                </div>
                <div className="income-list" data-cy="income-list">
                    {incomes.map((income) => {
                    const { _id, title, amount, date, category, description, type } = income;
                    return (
                        <Item
                        key={_id}
                        id={_id}
                        title={title}
                        description={description}
                        amount={amount}
                        date={date}
                        type={type}
                        category={category}
                        indicatorColor="var(--color-green)"
                        deleteItem={deleteIncome}
                        data-cy={`income-item-${_id}`}
                        />
                    );
                    })}
                </div>
                </div>
            </InnerLayout>
        </IncomeStyled>
    );
}

const IncomeStyled = styled.div`
    display: flex;
    overflow: auto;
    .total-income{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCF6F9;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 1rem;
        margin: 1rem 0;
        font-size: 2rem;
        gap: .5rem;
        span{
            font-size: 2.5rem;
            font-weight: 800;
            color: var(--color-green);
        }
    }
    .income-content{
        display: flex;
        gap: 2rem;
        .income-list{
            flex: 1;
        }
    }
`;

export default Income