import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Popups/Modal';

function LoginForm({ setEmail, setPassword}) {
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');

    const [emailText, setEmailText] = useState();
    const [passwordText, setPasswordText] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setEmail(String(emailText).toLowerCase());
        setPassword(passwordText);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setError('');
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <input
                className="login-input" data-cy="login-input-email"
                type="email"
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
                placeholder="Email"
                required
            />
            <input
                className="login-input" data-cy="login-input-password"
                type="password"
                value={passwordText}
                onChange={(e) => setPasswordText(e.target.value)}
                placeholder="Password"
                required
            />
            <button className="login-button" data-cy="login-button" type="submit">Login</button>
            {showModal && (
            <ModalStyled data-cy="login-modal-error">
                <h2>Login Error</h2>
                <p>{error || 'An unknown error occurred'}</p>
                <button onClick={handleCloseModal}>OK</button>
            </ModalStyled>
        )}
        </form>
    );
}

export default LoginForm;

const ModalStyled = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff0f0; // Light red background for error signaling
    padding: 2rem;
    border: 2px solid #ff4d4f; // Solid red border for emphasis on error
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    width: auto; // Automatically adjusts to content
    max-width: 80%; // Increases the width to 80% of the viewport width
    z-index: 1000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // Animation
    animation: showModalAnimation 0.3s ease-out forwards;

    @keyframes showModalAnimation {
        from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
        }
        to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        }
    }

    h2 {
        margin-bottom: 1rem;
        color: #ff4d4f; // Red color for the title to signify an error
        font-size: 1.75rem;
    }

    p {
        color: #ff4d4f; // Red color for the error message text
        margin-bottom: 2rem;
    }

    button {
        padding: 0.8rem 2.5rem;
        border: none;
        background-color: #000; // Should set the button color to black
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.2s;
    
        &:hover {
          background-color: #333; // Dark gray on hover for a subtle effect
        }
    }
`;
