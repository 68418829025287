import { useRef, useEffect } from 'react';

/**
 * Identical to React.useEffect, except that it never runs on mount (first render).
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
export function useEffectLazy(effect, dependencies) {
    const isMounted = useRef(false);
  
    useEffect(() => {
      if (isMounted.current) return effect();
      else isMounted.current = true;
    }, dependencies);
  
    // reset on unmount; in React 18, components can mount again
    useEffect(() => () => {
      isMounted.current = false;
    }, []);
  }