import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../context/userContext';
import SettingsForm from '../Forms/SettingsForm';
import { useEffectLazy } from '../../utils/useEffectLazy';

function Settings() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [retypedPassword, setRetypedPassword] = useState('');
    const [selectedImage, setSelectedImage] = useState(localStorage.getItem('profileImage') || 'robot');

    const [updateSuccess, setUpdateSuccess] = useState(false);

    const [updateError, setUpdateError] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    
    const { updateUserSettings } = useUserContext();

    const handleSubmit = async (e) => {
        setUpdateSuccess(false);
        setUpdateError('');
        
        const data = {}; // Create a JSON object to hold your data
        if (username) data.username = username;
        if (password) data.password = password;
        data.profileImage = selectedImage;

        try {
            await updateUserSettings(data); // Replace with your actual update function
            setUpdateSuccess(true);
            setUsername('');
            setPassword('');
            setRetypedPassword(''); // Clear the retyped password field

            if (timeoutId) clearTimeout(timeoutId);
            const id = setTimeout(() => {
                setUpdateSuccess(false);
            }, 2500);
            setTimeoutId(id);

        } catch (error) {
            setUpdateError('Failed to update settings. Please try again.');

            if (timeoutId) clearTimeout(timeoutId);
            const id = setTimeout(() => {
                setUpdateError('');
            }, 2500);
            setTimeoutId(id);
        }
        if (updateSuccess) {
            localStorage.setItem('profileImage', selectedImage);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    useEffectLazy( () => {
        handleSubmit();
    }, [username, password, retypedPassword, selectedImage]);

    return (
        <div>
            {updateSuccess && (
                <UpdateMessage className="update-success">
                Settings have been updated successfully!
                </UpdateMessage>
            )}
            {updateError && (
                <UpdateMessage className="update-error" error>
                {updateError}
                </UpdateMessage>
            )}
            <SettingsForm  setUsername={setUsername}
                            setPassword={setPassword} setRetypedPassword={setRetypedPassword}
                            setSelectedImage={setSelectedImage}
            />
        </div>
    );
}

// Add this for the success/error message styles
const UpdateMessage = styled.div`
    text-align: center;
    color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 5px;
    background-color: ${props => props.updateError ? '#ff3860' : '#23d160'};
`;

export default Settings;
