import React, { createContext, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const UserContext = createContext();
const cookies = new Cookies();

// Helper function to get the token from cookies
const getToken = () => cookies.get('token');

// Update Axios interceptor to use cookie token
axios.defaults.baseURL = BASE_URL;
axios.interceptors.request.use((config) => {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const loginUser = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}login`, { email, password });
      setUser(response.data.user);
      try {
        cookies.set('token', response.data.token, { path: '/' });
      } catch (err) {
        console.error("Error storing the token:", err);
      }
      // console.log("Stored token in cookies:", response.data.token);
      return response.data.token;
    } catch (err) {
      // console.error("Login error:", err);
      setError(err.response ? err.response.data.message : err.message);
      return null;
    }
  };

  const logoutUser = () => {
      cookies.remove('token', { path: '/' }); // Removing the token from cookies
      setUser(null); // Resets the user context
  };
    

  // Function to register a new user
  const registerUser = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}register`, { email, password });
      // Handle successful registration
    } catch (err) {
      // Handle registration error
    }
  };

  // Function to change user password, now using the getToken helper
  const changeUserPassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.put(`/user/password`, { currentPassword, newPassword });
      // Handle successful password change
    } catch (err) {
      setError(err.message);
    }
  };

  const updateUser = (newUserData) => {
    setUser((currentUser) => {
      const isUsernameChanged = newUserData.username && newUserData.username !== currentUser?.username;
      const isProfileImageChanged = newUserData.profileImage && newUserData.profileImage !== currentUser?.profileImage;
  
      if (isUsernameChanged || isProfileImageChanged) {
        return { ...currentUser, ...newUserData };
      }
  
      return currentUser;
    });
  };

  const updateUserSettings = async (settings) => {
    try {
      const response = await axios.put('/user/settings', settings);
      updateUser(response.data.user); // Use the new updateUser function here
      localStorage.setItem('username', response.data.user.username); // Update local storage
      localStorage.setItem('profileImage', response.data.user.profileImage);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const fetchUserFromToken = useCallback(async () => {
    if (cookies.get('token')) {
      try {
        const response = await axios.get(`/user`);
        setUser(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      }
    }
  }, [setUser, setError]);
  
  return (
    <UserContext.Provider value={{
      user,
      updateUser, // Add this line
      error,
      loginUser,
      logoutUser,
      registerUser,
      updateUserSettings,
      changeUserPassword,
      fetchUserFromToken,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);