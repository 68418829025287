import React from 'react'
import styled from 'styled-components'
import { dateFormat } from '../../utils/dateFormat';
import { bitcoin, book, calender, card, circle, clothing, comment, dollar, food, freelance, medical, money, piggy, stocks, takeaway, trash, tv, users, yt } from '../../utils/Icons';
import Button from '../Button/Button';

function Item({
    showDeleteButton = true, // Default to true if not provided
    id,
    title,
    amount,
    date,
    category,
    description,
    deleteItem,
    indicatorColor,
    type
}) {

    const categoryIcon = () =>{
        switch(category) {
            case 'Salary':
                return money;
            case 'Freelancing':
                return freelance
            case 'Investments':
                return stocks;
            case 'Stocks':
                return users;
            case 'Bitcoin':
                return bitcoin;
            case 'Bank':
                return card;
            case 'Youtube':
                return yt;
            case 'Other':
                return piggy;
            default:
                return ''
        }
    }

    const expenseCatIcon = () => {
        switch (category) {
            case 'Education':
                return book;
            case 'Groceries':
                return food;
            case 'Health':
                return medical;
            case 'Subscriptions':
                return tv;
            case 'Takeaways':
                return takeaway;
            case 'Clothing':
                return clothing;
            case 'Travelling':
                return freelance;
            case 'Other':
                return circle;
            default:
                return ''
        }
    }

    console.log('type', type)

    return (
        <ItemStyled indicator={indicatorColor} data-cy="item-container">
            <div className="icon" data-cy="item-icon">
                {type === 'expense' ? expenseCatIcon() : categoryIcon()}
            </div>
            <div className="content" data-cy="item-content">
                <h5 data-cy="item-title">{title}</h5>
                <div className="inner-content" data-cy="item-inner-content">
                    <div className="text">
                        <p data-cy="item-amount">{amount} CZK</p>
                        <p data-cy="item-date">{calender} {dateFormat(date)}</p>
                        <p data-cy="item-description">
                            {comment}
                            {description}
                        </p>
                    </div>
                    {showDeleteButton && (
                        <div className="btn-con" data-cy="item-delete-btn">
                            <Button 
                                icon={trash}
                                bPad={'1rem'}
                                bRad={'50%'}
                                bg={'var(--primary-color'}
                                color={'#fff'}
                                iColor={'#fff'}
                                hColor={'var(--color-green)'}
                                onClick={() => deleteItem(id)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ItemStyled>
    );
}

const ItemStyled = styled.div`
    background: #FCF6F9;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    color: #222260;
    .icon{
        width: 80px;
        height: 80px;
        border-radius: 20px;
        background: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
        i{
            font-size: 2.6rem;
        }
    }

    .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: .2rem;
        h5{
            font-size: 1.3rem;
            padding-left: 2rem;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: .8rem;
                height: .8rem;
                border-radius: 50%;
                background: ${props => props.indicator};
            }
        }

        .inner-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text{
                display: flex;
                align-items: center;
                gap: 1.5rem;
                p{
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: var(--primary-color);
                    opacity: 0.8;
                }
            }
        }
    }
`;

export default Item