import React, { useState, useMemo, useEffect } from 'react';
import styled from "styled-components";
import bg from './img/bg.png';
import { MainLayout } from './styles/Layouts';
import Orb from './Components/Orb/Orb';
import Navigation from './Components/Navigation/Navigation';
import Dashboard from './Components/Pages/Dashboard';
import Income from './Components/Pages/Income';
import Expenses from './Components/Pages/Expenses';
import Settings from './Components/Pages/Settings';
import LoginPage from './Components/Pages/LoginPage';
import { useGlobalContext } from './context/globalContext';
import Cookies from 'universal-cookie';
import { useUserContext } from './context/userContext';
import Transactions from './Components/Pages/Transactions';


function App() {       
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState(!!cookies.get('token'));
  const [active, setActive] = useState(1);
  const { user, fetchUserFromToken, logoutUser } = useUserContext();
  const global = useGlobalContext();

  useEffect(() => {
    if (cookies.get('token')) {
      fetchUserFromToken();
    }
  }, []);

  const handleLogin = async () => {
    // Assuming the login process is handled elsewhere and sets the token in cookies
    const token = cookies.get('token');
    if (token) {
      await fetchUserFromToken(); // Fetch user data based on the token
      setIsLoggedIn(true); // Set logged in state after user data is fetched
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    logoutUser();
  };

  const orbMemo = useMemo(() => <Orb />, []);

  const displayData = () => {
    switch (active) {
      case 1:
        return <Dashboard data-cy="dashboard-component" />;
      case 2:
        return <Transactions data-cy="transactions-component" />;
      case 3:
        return <Income data-cy="income-component" />;
      case 4:
        return <Expenses data-cy="expenses-component" />;
      case 5: 
        return <Settings data-cy="settings-component" />;
      default:
        return <Dashboard data-cy="default-dashboard-component" />;
    }
  };

  return (
      <AppStyled bg={bg} className="App">
        {orbMemo}
        <MainLayout className="main-layout" data-cy="main-layout">
          {isLoggedIn ? (
            <>
              <Navigation className="navigation-component" data-cy="navigation-component" active={active} setActive={setActive} onLogout={logout} />
              <main className="main-content" data-cy="main-content">{displayData()}</main>
            </>
          ) : (
            <LoginPage className="login-page" data-cy="login-page" onLogin={handleLogin} />
          )}
        </MainLayout>
      </AppStyled>
  );
}

const AppStyled = styled.div`
  height: 100vh;
  background-image: url(${props => props.bg});
  position: relative;
  main {
    flex: 1;
    background: rgba(252, 246, 249, 0.78);
    border: 3px solid #FFFFFF;
    backdrop-filter: blur(4.5px);
    border-radius: 32px;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export default App;
