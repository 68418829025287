import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GlobalProvider } from './context/globalContext';
import { UserProvider } from './context/userContext';
import { GlobalStyle } from './styles/GlobalStyle';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <GlobalStyle /> 
  <UserProvider> 
      <GlobalProvider>
          <App data-cy="app" />
      </GlobalProvider>
  </UserProvider>
</React.StrictMode>
);
