import React, { useState } from 'react';
import { useEffectLazy } from '../../utils/useEffectLazy';
import styled from 'styled-components';
import Modal from '../Popups/Modal';
import Header from '../Header/Header';
import LoginForm from '../Forms/LoginForm'; // Import the new LoginForm component
import { useUserContext } from '../../context/userContext'; 

function LoginPage({ onLogin }) {

    const { loginUser } = useUserContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setError('');
    };

    const handleLogin = async () => {
        try {
            const token = await loginUser(email, password);
            if (token) {
                console.log("Login successful. Token:", token);
                onLogin();
            } else {
                setError('The email address does not exist or the password is incorrect.');
                setShowModal(true);
            }
        } catch (err) {
            if (err.message === 'User not found') {
                setError('The email address does not exist.');
            } else {
                setError(err.message || 'An error occurred during login.');
            }
            setShowModal(true);
        }
    }

    useEffectLazy( () => {
        handleLogin();
     }, [email, password]) 

    return (
        <div className="login-page-container">
            <StyledLoginPage>
                <Header title="Expense tracking app" />
                <h3>Please enter login credentials</h3>
                <LoginForm 
                     setEmail={setEmail}
                     setPassword={setPassword}
                />
                {showModal && (
                    <ModalStyled>
                        <h2>Login Error</h2>
                        <p>{error || 'An unknown error occurred'}</p>
                        <button onClick={handleCloseModal}>OK</button>
                    </ModalStyled>
                )}
            </StyledLoginPage>
        </div>
    );
}

const ModalStyled = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff0f0; // Light red background for error signaling
    padding: 2rem;
    border: 2px solid #ff4d4f; // Solid red border for emphasis on error
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    width: auto; // Automatically adjusts to content
    max-width: 80%; // Increases the width to 80% of the viewport width
    z-index: 1000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // Animation
    animation: showModalAnimation 0.3s ease-out forwards;

    @keyframes showModalAnimation {
        from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
        }
        to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        }
    }

    h2 {
        margin-bottom: 1rem;
        color: #ff4d4f; // Red color for the title to signify an error
        font-size: 1.75rem;
    }

    p {
        color: #ff4d4f; // Red color for the error message text
        margin-bottom: 2rem;
    }

    button {
        padding: 0.8rem 2.5rem;
        border: none;
        background-color: #000; // Should set the button color to black
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.2s;
    
        &:hover {
          background-color: #333; // Dark gray on hover for a subtle effect
        }
    }
`;

const StyledLoginPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    position: fixed;
    top: 50%; // Adjusted to 50% to center vertically
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-width: 80%;
    z-index: 10;

    form {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    button {
        padding: 10px;
        background-color: #0056b3; // Adjusted to match ModalStyled button color
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.2s;

        &:hover {
            background-color: #004494;
        }
    }
`;

export default LoginPage;
