import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/globalContext';
import { InnerLayout } from '../../styles/Layouts';
import PieChart from '../Chart/Pie';
import Item from '../Item/Item'; // Assuming you have an IncomeItem component

function Transactions() {
    const { incomes, expenses, getIncomes, getExpenses, deleteIncome, deleteExpense } = useGlobalContext();

    useEffect(() => {
        getIncomes();
        getExpenses();
    }, []);

    return (
        <TransactionsStyled>
            <InnerLayout>
            <h1 className="transactions-title" data-cy="header">Transaction details</h1>
            <div className="charts-container">
                <PieChart className="income-pie-chart" type="income" />
                <PieChart className="expenses-pie-chart" type="expenses" />
            </div>
            <div className="history-container">
                <div className="income-history">
                <h2 className="income-history-title">Income History</h2>
                {incomes.map((income) => {
                    const { _id, title, amount, date, category, description, type } = income;
                    return (
                    <Item
                        key={_id}
                        id={_id}
                        title={title}
                        description={description}
                        amount={amount}
                        date={date}
                        type={type}
                        category={category}
                        indicatorColor="var(--color-green)"
                        showDeleteButton={false} // Default to true if not provided
                        // deleteItem={deleteIncome}
                    />
                    );
                })}
                </div>
                <div className="expense-history">
                <h2 className="expense-history-title">Expense History</h2>
                {expenses.map((expense) => {
                    const { _id, title, amount, date, category, description, type } = expense;
                    return (
                    <Item
                        key={_id}
                        id={_id}
                        title={title}
                        description={description}
                        amount={amount}
                        date={date}
                        type={type}
                        category={category}
                        indicatorColor="var(--color-red)" // Assuming red color for expenses
                        showDeleteButton={false} // Default to true if not provided
                        // deleteItem={deleteExpense}
                    />
                    );
                })}
                </div>
            </div>
            </InnerLayout>
        </TransactionsStyled>
    );
}

const TransactionsStyled = styled.div`
    .charts-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: flex-start; // Align charts to the top
        gap: 20px; // Provides consistent spacing between charts
    }

    .history-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px; // Space between charts and history sections
        gap: 20px; // Provides consistent spacing between history items
    }

    .income-history, .expense-history {
        flex: 1 0 45%; // Adjust the flex-basis to control the initial width
        max-width: calc(50% - 20px); // Ensure that they don't exceed half the container width minus the gap
        margin: 0; // Remove any margin
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: adds shadow for depth
        border-radius: 8px; // Optional: rounds the corners of the history items
        overflow: hidden; // Prevents content from spilling out
    }

    .pie-chart {
        width: 100%;
        max-width: 400px; // Set a maximum width for the pie charts
        height: auto; // Height should be automatic to maintain aspect ratio
        margin: 0 auto; // Centers the chart
        text-align: center; // Center the text inside the .pie-chart div
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: adds shadow for depth
        border-radius: 8px; // Optional: rounds the corners of the charts
    }

    // Responsive design for smaller screens
    @media (max-width: 768px) {
        .charts-container > div, .history-container > div {
            flex: 1 0 100%; // Each item takes full width on smaller screens
            max-width: 100%; // Allow the pie charts to use the full width
        }
        .income-history, .expense-history {
            order: 3; // Push history items to the end
        }
        .pie-chart {
            order: 2; // Keep pie charts in the middle
        }
    }
    
    // Use this if you need a min-height to prevent layout shifts
    .pie-chart-container {
        min-height: 350px; // Adjust based on the actual height of your charts
    }
    
    // Ensure text doesn't resize on hover
    h1, h2 {
        transition: none;
    }
`;

export default Transactions;