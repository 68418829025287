import React, { useContext, useState } from "react"
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const GlobalContext = React.createContext()

export const GlobalProvider = ({children}) => {

    const [incomes, setIncomes] = useState([])
    const [expenses, setExpenses] = useState([])
    const [error, setError] = useState(null)

    // Helper function to create the authorization header
    const getAuthHeader = () => {
        const token = localStorage.getItem('token');
        return { headers: { Authorization: `Bearer ${token}` } };
    };

    // Example function that uses the auth header
    const addIncome = async (income) => {
        try {
            const config = getAuthHeader();
            const response = await axios.post(`${BASE_URL}add-income`, income, config);
            // console.log("API response for addIncome:", response.data);
            setIncomes(prevIncomes => [...prevIncomes, response.data]);
        } catch (err) {
        setError(err.response.data.message);
        }
    };

    // Example function that uses the auth header
    const getIncomes = async () => {
        try {
            const config = getAuthHeader();
            const response = await axios.get(`${BASE_URL}get-incomes`, config);
            setIncomes(response.data);
        } catch (error) {
            console.error(error);
            setError(error.response.data.message);
        }
    };

    const deleteIncome = async (id) => {
        try {
            const config = getAuthHeader();
            const res  = await axios.delete(`${BASE_URL}delete-income/${id}`, config)
            getIncomes()
        } catch (error) {
            setError(error.response.data.message);
        }
    }

    const totalIncome = () => {
        let totalIncome = 0;
        incomes.forEach((income) =>{
            totalIncome = totalIncome + income.amount
        })

        return totalIncome;
    }

    // Add an expense
    const addExpense = async (expense) => {
        try {
            const config = getAuthHeader();
            const response = await axios.post(`${BASE_URL}add-expense`, expense, config);
            //getExpenses();
            setExpenses(prevExpenses => [...prevExpenses, response.data]);
        } catch (err) {
            setError(err.response.data.message);
        }
    };

    // Get all expenses
    const getExpenses = async () => {
        try {
            const config = getAuthHeader();
            const response = await axios.get(`${BASE_URL}get-expenses`, config);
            setExpenses(response.data);
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    // Delete an expense
    const deleteExpense = async (id) => {
        try {
            const config = getAuthHeader();
            await axios.delete(`${BASE_URL}delete-expense/${id}`, config);
            getExpenses();
        } catch (error) {
            setError(error.response.data.message);
        }
    };

    const totalExpenses = () => {
        let totalIncome = 0;
        expenses.forEach((income) =>{
            totalIncome = totalIncome + income.amount
        })

        return totalIncome;
    }

    const totalBalance = () => {
        return totalIncome() - totalExpenses()
    }

    const transactionHistory = () => {
        const history = [...incomes, ...expenses]
        history.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
        })

        return history.slice(0, 3)
    }


    return (
        <GlobalContext.Provider value={{
            addIncome,
            getIncomes,
            incomes,
            deleteIncome,
            expenses,
            totalIncome,
            addExpense,
            getExpenses,
            deleteExpense,
            totalExpenses,
            totalBalance,
            transactionHistory,
            error,
            setError
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () =>{
    return useContext(GlobalContext)
}