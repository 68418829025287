import React, { useEffect } from 'react'
import styled from 'styled-components'
import avatarMale from '../../img/male.png';
import avatarFemale from '../../img/female.png';
import avatarDog from '../../img/dog.png';
import avatarCat from '../../img/cat.png';
import avatarRobot from '../../img/robot.png'
import { signout, settings } from '../../utils/Icons'
import { menuItems } from '../../utils/menuItems'
import { useUserContext } from '../../context/userContext'; // Adjust the import path as necessary

function Navigation({ active, setActive, onLogout }) {
    const { user } = useUserContext(); // Destructuring to get the user object

    // Default avatar images
    const defaultAvatarMale = avatarMale;
    const defaultAvatarFemale = avatarFemale;
    const defaultAvatarDog = avatarDog;
    const defaultAvatarCat = avatarCat;
    const defaultAvatarRobot = avatarRobot;

    useEffect(() => {
        console.log("User state changed", user);
    }, [user]);
    
    // Determine which avatar image to use based on the profileImage enum
    const getAvatarImage = (profileImage) => {
        switch (profileImage) {
            case 'male':
                return defaultAvatarMale;
            case 'female':
                return defaultAvatarFemale;
            case 'dog':
                return defaultAvatarDog;
            case 'cat':
                return defaultAvatarCat;
            case 'robot':
                return defaultAvatarRobot;
            default:
                return defaultAvatarRobot; // Use Robot as the default
        }
    }

    // Determine which name to use based on the username or a default name
    const getUsername = (username) => {
        return username || "User"; // Use the provided username or "User" as the default
    }

    return (
        <NavStyled>
            <div className="user-container">
                <img className="user-avatar" src={getAvatarImage(user?.profileImage)} alt="User Avatar" />
                <div className="user-text">
                    <h2 className="user-username">{getUsername(user?.username)}</h2>
                    <p className="user-money">Your Money</p>
                </div>
            </div>
                <ul className="menu-items">
                    {menuItems.map((item) => {
                    return <li
                        key={item.id}
                        onClick={() => setActive(item.id)}
                        className={`menu-item ${active === item.id ? 'active' : ''}`}
                        >
                        {item.icon}
                        <span className="menu-item-title">{item.title}</span>
                        </li>
                    })}
                </ul>
            <div className="bottom-nav">
                <ul className="menu-items">
                {/* Settings item */}
                <li
                    onClick={() => setActive(5)} // Assuming '5' is the ID for Settings
                    className={`menu-item settings-item ${active === 5 ? 'active' : ''}`}
                >
                    {settings}
                    <span className="menu-item-title">Settings</span>
                </li>
                {/* Sign out item */}
                <li onClick={onLogout} className="menu-item signout-button">
                    {signout}
                    <span className="menu-item-title">Sign Out</span>
                </li>
            </ul>
        </div>
        </NavStyled>
    )
}

const NavStyled = styled.nav`
    padding: 2rem 1.5rem;
    max-width: 300px; // Ensures that the nav doesn't exceed this width
    width: 80%; // Allows the nav to be responsive to the container's width
    height: 100%;
    background: rgba(252, 246, 249, 0.78);
    border: 3px solid #FFFFFF;
    backdrop-filter: blur(4.5px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    // Responsive Media Query for mobile devices
    @media screen and (max-width: 768px) {
        padding: 1rem;
        .user-container img {
        width: 80px; // Adjust the image size for smaller screens
        height: 80px; // Adjust the image size for smaller screens
        }
        // More adjustments as needed...
    }

    .user-container {
        height: 100px;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover; // Changed from 'fill' to 'cover' for a better image fit
        background: #fcf6f9;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06);
        }

        h2 {
        color: rgba(34, 34, 96, 1);
        }

        p {
        color: rgba(34, 34, 96, .6);
        }
    }

    .menu-items {
        flex: 1;
        display: flex;
        flex-direction: column;
        
        li {
        display: grid;
        grid-template-columns: 40px auto;
        align-items: center;
        margin: .6rem 0;
        font-weight: 500;
        cursor: pointer;
        transition: all .4s ease-in-out;
        color: rgba(34, 34, 96, .6);
        padding-left: 1rem;
        position: relative;

        i {
            color: rgba(34, 34, 96, 0.6);
            font-size: 1.4rem;
            transition: all .4s ease-in-out;
        }
        }
    }

    .active {
        color: rgba(34, 34, 96, 1) !important;
        
        i {
        color: rgba(34, 34, 96, 1) !important;
        }

        &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: #222260;
        border-radius: 0 10px 10px 0;
        }
    }

    .bottom-nav {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signout-button {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    margin: 0.6rem 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    color: rgba(34, 34, 96, 0.6);
    background: none;
    border: none;
    padding: 0;
    padding-left: 1rem;
    font-size: inherit;
    text-align: left;

    &:hover {
        color: rgba(34, 34, 96, 1);
        
        i {
            color: rgba(34, 34, 96, 1);
        }
    }

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.4rem;
        color: rgba(34, 34, 96, 0.6);
    }
}`;

export default Navigation