import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useGlobalContext } from '../../context/globalContext';

function PieChart({ type }) {
    const { incomes, expenses } = useGlobalContext();
    
    // Predefined list of colors from the image provided.
    const predefinedColors = [
        "#56038D", "#FF0097", "#FF655F", "#FBA600", "#FFEC00", "#DAF800", "#009665"
    ];

    // Function to get a color from predefinedColors based on category index
    const getColorForCategory = (category, categoriesList) => {
        const index = categoriesList.indexOf(category);
        return predefinedColors[index % predefinedColors.length];
    };

    // Prepare category data and colors
    const data = type === 'income' ? incomes : expenses;
    const categoriesList = [...new Set(data.map(item => item.category))];
    const categoryData = data.reduce((result, item) => {
        if (!result[item.category]) {
            result[item.category] = 0;
        }
        result[item.category] += item.amount;
        return result;
    }, {});

    const labels = Object.keys(categoryData);
    const dataValues = Object.values(categoryData);
    const colors = labels.map(label => getColorForCategory(label, categoriesList));

    const pieData = {
        labels: labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const pieOptions = {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true, // Enable tooltips
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed !== null) {
                            label += context.parsed + ' CZK'; // You can adjust the unit 'CZK' as needed
                        }
                        return label;
                    }
                }
            },
        },
        animation: {
            animateRotate: false,
        },
    };
    

    const title = type === 'income' ? 'Incomes' : 'Expenses';

    return (
        <div className="pie-chart">
            <h2>{title}</h2>
            <Pie data={pieData} options={pieOptions} />
        </div>
    );
}

export default PieChart;
