import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useGlobalContext } from '../../context/globalContext';
import { InnerLayout } from '../../styles/Layouts';
import Item from '../Item/Item';
import FinancialForm from '../Forms/FinancialForm';
import { useEffectLazy } from '../../utils/useEffectLazy';

function Expenses() {
    const {addExpense, error, setError, expenses, getExpenses, deleteExpense, totalExpenses} = useGlobalContext()

    const [formValues, setFormValues] = useState({
        title: '',
        amount: '',
        date: null,
        category: '',
        description: '',
        currency: '',
    });

    const amountRef = useRef(formValues.amount);
    const currencySwap = async () => {
        let target = 'czk';
        try {
            const response = await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${formValues.currency.toLowerCase()}/${target}.json`);
            let data = await response.json();
            amountRef.current = Math.round(data[target]*formValues.amount);
        } catch (err) {
            setError(`Could not transfer from ${formValues.currency}, use CZK for now.`);
        }
    }

    useEffect(() =>{
        getExpenses();
    }, [])

    const handleFormSubmit = async (values) => {
        addExpense(values);
    }

    useEffectLazy( () => {
        if (formValues.currency != 'CZK') {
            currencySwap().then(() => {
                let submitData = formValues;
                submitData.amount = amountRef.current;
                handleFormSubmit(submitData);
            });
        } else {
            handleFormSubmit(formValues);
        }
        getExpenses();
     }, [formValues])

    return (
        <ExpenseStyled data-cy="expense-container">
            <InnerLayout>
                <h1 className="expense-title" data-cy="header">Expenses</h1>
                <h2 className="total-expense" data-cy="total">
                    Total Expense: <span data-cy="expense-value">{totalExpenses()} CZK</span>
                </h2>
                <div className="expense-content">
                    <div className="form-container" data-cy="form-container">
                        <FinancialForm formType='expense'
                            formValues={formValues} setFormValues={setFormValues}
                        />
                    </div>
                    <div className="expense-list" data-cy="expense-list">
                        {expenses.map((expense) => {
                            const {_id, title, amount, date, category, description, type} = expense;
                            // Use _id as key for React list rendering
                            return <Item
                                key={_id}
                                id={_id} 
                                title={title} 
                                description={description} 
                                amount={amount} 
                                date={date} 
                                type={type}
                                category={category} 
                                indicatorColor="var(--color-green)"
                                deleteItem={deleteExpense}
                                data-cy={`item-${_id}`} // Add data-cy attribute for each item
                            />
                        })}
                    </div>
                </div>
            </InnerLayout>
        </ExpenseStyled>
    )
}

const ExpenseStyled = styled.div`
    display: flex;
    overflow: auto;
    .total-expense{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCF6F9;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 1rem;
        margin: 1rem 0;
        font-size: 2rem;
        gap: .5rem;
        span{
            font-size: 2.5rem;
            font-weight: 800;
            color: var(--color-green);
        }
    }
    .expense-content{
        display: flex;
        gap: 2rem;
        .expense-list{
            flex: 1;
        }
    }
`;

export default Expenses