import logo from '../../img/logo.png'
import styled from 'styled-components';

const Header = ({ title }) => (
    <StyledHeader>
        <h1 className="header-title">{title}</h1>
        <img
        src={logo}
        alt="Piggy bank logo"
        className="header-logo"
        />
    </StyledHeader>
);

// Styled header component
const StyledHeader = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; // Adds some space below the header

    .header-title {
        font-size: 2rem; // Adjust the size as needed
        color: #333; // A color that suits your design
        text-align: center;
        margin-bottom: 1rem; // Adds space between title and logo
    }

    .header-logo {
        width: auto; // Keeps image aspect ratio
        height: 350px; // Adjust height to fit your design
        // Remove max-width and max-height if setting specific dimensions
    }
    `;

export default Header;