import React, { useState } from 'react';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import Button from '../Button/Button';
import avatarMale from '../../img/male.png';
import avatarFemale from '../../img/female.png';
import avatarDog from '../../img/dog.png';
import avatarCat from '../../img/cat.png';
import avatarRobot from '../../img/robot.png'

function SettingsForm({setUsername, setPassword, setRetypedPassword, setSelectedImage}) {
    const [usernameLocal, setUsernameLocal] = useState('');
    const [passwordLocal, setPasswordLocal] = useState('');
    const [retypedPasswordLocal, setRetypedPasswordLocal] = useState('');

    const [selectedImageLocal, setSelectedImageLocal] = useState(localStorage.getItem('profileImage') || 'robot');
    const [error, setError] = useState('');

    // Use the imported images
    const defaultAvatarMale = avatarMale;
    const defaultAvatarFemale = avatarFemale;
    const defaultAvatarDog = avatarDog;
    const defaultAvatarCat = avatarCat;
    const defaultAvatarRobot = avatarRobot;

    // Predefined images (replace these URLs with your actual images)
    const imageOptions = [
        { id: 'male', url: defaultAvatarMale, checked: selectedImageLocal === 'male' },
        { id: 'female', url: defaultAvatarFemale, checked: selectedImageLocal === 'female' },
        { id: 'dog', url: defaultAvatarDog, checked: selectedImageLocal === 'dog' },
        { id: 'cat', url: defaultAvatarCat, checked: selectedImageLocal === 'cat' },
        { id: 'robot', url: defaultAvatarRobot, checked: selectedImageLocal === 'robot'}
    ];

    const handleUsernameChange = (e) => setUsernameLocal(e.target.value);
    const handlePasswordChange = (e) => setPasswordLocal(e.target.value);
    const handleRetypedPasswordChange = (e) => setRetypedPasswordLocal(e.target.value);
    const handleImageSelection = (e) => {
        const imageId = e.target.value;
        setSelectedImageLocal(imageId);
        localStorage.setItem('profileImage', imageId); // Save the selection to local storage
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        // testable decision points here
        if (usernameLocal && (usernameLocal.length > 7 || !/[a-zA-Z]/.test(usernameLocal))) {// username must be <= 7 and must contain alo letter
            setError('Username must be shorter than 8 chars and must contain at least one letter.');
            setUsernameLocal('');
            return;
        }
        if (passwordLocal && !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/.test(passwordLocal)) { //password must: len >= 8; ato capital; ato low case; ato num; ato special char.
            setError(`Password must have:<br>
            • at least 8 character length<br>
            • at least 1 capital letter<br>
            • at least 1 lower case letter<br>
            • at least 1 number<br>
            • at least 1 special character`);
            setPasswordLocal('');
            setRetypedPasswordLocal('');
            return;
        }
        if (passwordLocal && passwordLocal !== retypedPasswordLocal) {
            setError('Passwords do not match.');
            setRetypedPasswordLocal('');
            return;
        }

        setUsername(usernameLocal);
        setPassword(passwordLocal);
        setRetypedPassword(retypedPasswordLocal);
        setSelectedImage(selectedImageLocal);
    };

    return (
        <SettingsStyled data-cy="settings-page">
            <InnerLayout>
                <div className="settings-content">
                <h1 className="settings-title" data-cy="header">User Settings</h1>
                <form onSubmit={handleSubmit} data-cy="settings-form">
                    <div className="input-control" data-cy="settings-input-username"> 
                        <input
                        className="settings-input" 
                        type="text"
                        id="username"
                        placeholder="Change username"
                        value={usernameLocal}
                        onChange={handleUsernameChange}
                        />
                    </div>
                    <div className="password-container">
                        <div className="input-control password" data-cy="settings-input-password">
                            <input
                                type="password" 
                                id="password"
                                placeholder="Change password"
                                value={passwordLocal}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <div className={`input-control retype-password ${passwordLocal ? 'visible' : ''}`} data-cy="settings-input-retype-password">
                            <input
                                type="password" 
                                placeholder="Retype password"
                                value={retypedPasswordLocal}
                                onChange={handleRetypedPasswordChange}
                            />
                        </div>
                    </div>
                    <div className="input-control">
                        <div className="image-options">
                        {imageOptions.map((image) => (
                            <label key={image.id} className="image-option">
                            <input
                                type="radio"
                                name="profileImage"
                                value={image.id}
                                checked={selectedImageLocal === image.id}
                                onChange={handleImageSelection}
                            />
                            <img src={image.url} alt={`Avatar ${image.id}`} className="avatar-image" />
                            </label>
                        ))}
                        </div>
                    </div>
                    {error && <p className='error' style={{ color: 'red' }} data-cy="settings-error">{error}</p>}
                    <div className="submit-btn" data-cy="settings-submit-button">
                        <Button
                        type="submit" 
                        name="Save changes"
                        bPad=".8rem 1.6rem"
                        bRad="30px"
                        bg="var(--color-accent"
                        color="#fff"
                        />
                    </div>
                </form>
            </div>
            </InnerLayout>
        </SettingsStyled>
    );
}

// Base styles for the inputs and select tags within the .input-control class
const sharedInputStyles = `
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: 2px solid rgba(34, 34, 96, 0.4);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background: #fff;
    color: rgba(34, 34, 96, 0.9);
    width: 100%;
    height: 40px;

    &::placeholder {
        color: rgba(34, 34, 96, 0.4);
    }
`;

// Styled-components for Settings
const SettingsStyled = styled.div`
    .settings-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin: 1rem;
        font-family: 'Arial', sans-serif;

        h1 {
            margin-bottom: 3rem;
            font-size: 2.5rem;
            color: #333;
        }   

        form {
            width: 40%; // Increase the width to fit the parent container or as per your design
            max-width: 400px; // Adjust the maximum width as needed
            min-width: 200px; // Adjust the maximum width as needed
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .input-control {
                margin-bottom: 1.5rem;

                input,
                select {
                    ${sharedInputStyles}
                }

                .image-options {
                    display: flex;
                    gap: 20px; // Adjust the gap between images as needed
                    justify-content: center; // Center the images horizontally
        
                    .image-option {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        gap: 0.5rem;
        
                        .avatar-image {
                            width: 120px; // Adjust the width of the images as needed
                            height: 120px; // Adjust the height of the images as needed
                            border-radius: 50%;
                            object-fit: cover;
                            background: #fcf6f9;
                            // border: 2px solid #FFFFFF;
                            // padding: .2rem;
                            box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.06);
                        }
                    }
                }
            }

            .submit-btn {
                display: flex;
                justify-content: center;

                button{
                    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
                    transition: background-color 0.3s ease;

                    &:hover{
                        background: var(--color-green) !important;
                    }
                }
            }

            .password-container {
                display: flex;
                justify-content: space-between;
            }
            
            .input-control {
                flex: 1;
                margin-right: 10px; /* Adjust as needed */
                transition: all 0.5s ease-in-out;
            }
            
            .input-control.retype-password {
                max-width: 0;
                overflow: hidden;
                opacity: 0;
                transition: all 0.5s ease-in-out;
            }
            
            .input-control.retype-password.visible {
                max-width: 100%; /* Adjust to fit your layout */
                opacity: 1;
            }
        }
    }
`;

export default SettingsForm;
