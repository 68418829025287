import 'rsuite/dist/rsuite.min.css';
import { DatePicker } from 'rsuite';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/globalContext';
import Button from '../Button/Button';
import { plus } from '../../utils/Icons';

function FinancialForm({ formType, formValues, setFormValues}) {
    const [error, setError] = useState('');

    const categories = formType === 'expense' ?
        ['Education', 'Groceries', 'Health', 'Subscriptions', 'Takeaways', 'Clothing', 'Travelling', 'Other'] :
        ['Salary', 'Freelancing', 'Investments', 'Bank', 'Other'];
    const currencies = ['CZK', 'EUR', 'BTC'];

    const [inputState, setInputState] = useState({
        title: '',
        amount: '',
        date: new Date(),
        category: '',
        description: '',
        currency: 'CZK',
    });

    const { title, amount, date, category, description, currency } = inputState;

    const handleInput = name => e => {
        setInputState({ ...inputState, [name]: e.target.value });
    };

    function lteDecimals(number, maxDecimals) {
        if (isNaN(number)) return false; // Not a number
      
        const decimalPart = number.toString().split('.')[1];
        if (!decimalPart) return true; // Integer
      
        return decimalPart.length <= maxDecimals;
      }

    const handleSubmit = e => {
        e.preventDefault();

        /**
         * TESTABLE DECISION POINTS HERE
         */
        // title
        if (title.length > 50 || !/[a-zA-Z]/.test(title)) { //typeof title !== 'string' || 
            setError('Title format wrong!');
            return;
        }
        // amount
        if (!amount || amount <= 0) {
            setError("Positive amount must be specified!");
            return;
        }
        if (!currencies.includes(currency)) {
            setError("Unsupported currency chosen!");
            return;
        }
        // currency
        if (currency == "CZK") {
            if (!lteDecimals(amount, 0)) {
                setError("CZK amount must be integer!");
                return;
            }
            if (amount >= 1000000000) {
                setError("CZK amount must be < 1000000000!");
                return;
            }
        }
        if (currency == "EUR") {
            if (!lteDecimals(amount, 2)) {
                setError("EUR amount must have max 2 decimal digits!");
                return;
            }
            if (amount >= 41000000) {
                setError("EUR amount must be < 41000000!");
                return;
            }   
        }
        if (currency == "BTC") {
            if (!lteDecimals(amount, 6)) {
                setError("BTC amount must have max 6 decimal digits");
                return;
            }
            if (amount >= 1020) {
                setError("BTC amount must be < 1020!")
                return;
            }
        }
        // category
        if (!categories.includes(category)) {
            setError("Unsupported category chosen!");
            return;
        }
        // description
        if (description.length > 200 || !/^(?=.*[a-zA-Z])|^[\s]*$/.test(description)) {
            setError("Description must contain text, or be empty! (max 200 characters)");
            return;
        }

        setFormValues(inputState);
        setInputState({
            title: '',
            amount: '',
            date: new Date(),
            category: '',
            description: '',
            currency: 'CZK'
        });
        setError('');
    };

    return (
        <FormStyled onSubmit={handleSubmit}>
            {error && <p className='error'>{error}</p>}
            <div className="input-control" data-cy="input-control-title">
                <input 
                    type="text" 
                    value={title}
                    name='title' 
                    placeholder={`${formType === 'expense' ? 'Expense' : 'Income'} Title`}
                    onChange={handleInput('title')}
                />
            </div>
            <div className="flex-row">
                <div className="input-control" data-cy="input-control-amount">
                    <input 
                        value={amount}  
                        type="text" 
                        name='amount' 
                        placeholder={`${formType === 'expense' ? 'Expense' : 'Income'} Amount`}
                        onChange={handleInput('amount')} 
                    />
                </div>
                <div className="selects input-control" data-cy="selects-input-control-currency">
                    <select required value={currency} name="currency" onChange={handleInput('currency')}>
                        <option value="" disabled>Currency</option>
                        {currencies.map((cur, index) => (
                            <option key={index} value={cur}>{cur}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="input-control" data-cy="input-control-datepicker">
                <DatePicker 
                    appearance="default"
                    size="lg"
                    className="date-picker"
                    oneTap format="dd/MM/yyyy"
                    value={date} // Use null if date is not set
                    onChange={(date) => {
                        setInputState({...inputState, date: date})
                    }}
                    placeholder="Select a date"
                />
            </div>
            <div className="selects input-control" data-cy="selects-input-control-category">
                <select required value={category} name="category" onChange={handleInput('category')}>
                    <option value="" disabled>Select Option</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
            </div>
            <div className="input-control" data-cy="input-control-description">
                <textarea 
                    name="description" 
                    value={description} 
                    placeholder='Description' 
                    id="description" 
                    cols="30" 
                    rows="4" 
                    onChange={handleInput('description')}
                ></textarea>
            </div>
            <div className="submit-btn" data-cy="submit-btn">
                <Button 
                    name={`Add ${formType === 'expense' ? 'Expense' : 'Income'}`}
                    icon={plus}
                    bPad={'.8rem 1.6rem'}
                    bRad={'30px'}
                    bg={'var(--color-accent)'}
                    color={'#fff'}
                />
            </div>
        </FormStyled>
    )
}

const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    input, textarea, select {
        font-family: inherit;
        font-size: inherit;
        padding: .5rem 1rem;
        border-radius: 5px;
        background: #fff;
        border: 1.5px solid #ccc; /* Default gray border */
        box-shadow: none; /* Remove any box-shadow if not needed */
        color: rgba(34, 34, 96, 0.9);
        transition: border-color 0.2s; /* Smooth transition for the border color */
        
        &:hover {
        border-color: #b3d7ff; /* Very thin light blue on hover */
        }

        &:focus {
        outline: none;
        border-color: #b3d7ff; /* Light blue border for focus state */
        box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25); /* Optional: add a light glow effect on focus */
        }
    }

    .input-control {
        input {
        width: 100%;
        height: calc(1.5em + .75rem + 2px); /* Adjust the height as necessary */
        }
    }

    .selects {
        display: flex;
        justify-content: space-between;
        select {
        cursor: pointer;
        &:focus, &:active {
            color: rgba(34, 34, 96, 1);
        }
        }
    }

    .flex-row {
        display: flex;
        justify-content: space-between;
    }

    .submit-btn {
        button {
        cursor: pointer;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        &:hover {
            background: var(--color-green) !important;
        }
        }
    }
    `;

export default FinancialForm;
