import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalBox = styled.div`
    background-color: white;
    border-radius: 10px;
    width: 50%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative; // Needed for absolute positioning of children
`;

const ModalHeader = styled.div`
    background-color: #f8d7da; // Light red background for the header
    color: #721c24; // Dark text for contrast
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 10px; // Match the ModalBox border-radius
    border-top-right-radius: 10px; // Match the ModalBox border-radius
`;

const ActionButton = styled.button`
    padding: 10px 20px; // Padding inside the button
    border: none; // No border for the button
    background-color: #dc3545; // Red background color for the button
    color: white; // White text for contrast
    cursor: pointer; // Cursor indicates a clickable button
    font-size: 16px; // Font size for the text inside the button
    border-radius: 4px; // Rounded corners for the button
    margin: 10px; // Margin around the button, creating space between the button and the modal edges
    width: calc(100% - 40px); // Full width minus the left and right margins
    box-sizing: border-box; // The box-sizing property is used to tell the browser to account for any border and padding in the values you specify for an element's width and height.

    &:hover {
        background-color: #bd2130; // A slightly darker red for the hover state
    }
`;

const ModalTitle = styled.h2`
    margin: 0;
`; 

const ModalContent = styled.div`
    padding: 20px;
`;

// Use your existing ButtonStyled for other buttons if needed
function Modal({ onClose, children, title, showRegister }) {
    return (
        <ModalOverlay className="modal-overlay" onClick={onClose}>
        <ModalBox className="modal-box" onClick={(e) => e.stopPropagation()}>
            <ModalHeader className="modal-header">
            <ModalTitle className="modal-title">{title || 'Error'}</ModalTitle>
            </ModalHeader>
            <ModalContent className="modal-content">
            {children}
            {showRegister ? (
                <ActionButton className="register-button" onClick={() => {}}>Register</ActionButton>
            ) : (
                <ActionButton className="ok-button" onClick={onClose}>OK</ActionButton>
            )}
            </ModalContent>
        </ModalBox>
        </ModalOverlay>
    );
}

export default Modal;
